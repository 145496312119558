import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import forbricksImage from "../images/4bricks.png"
import confluxImage from "../images/conflux.png"
import learning3Image from "../images/learning3.png"
import loveMondaysImage from "../images/love-mondays.png"
import monsterosterImage from "../images/monster-roster.png"
import seuAtletaImage from "../images/seuatleta.png"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <main id="home">

      <section id="bring-your-idea" className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-offset-1 col-md-2">
            <p>Lets build amazing things together.</p>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-9">
            <div className="clipped clipped-bring-your-idea">
              <h1><a href="/contact" className="line-link">Bring your idea.</a></h1>
            </div>
          </div>
        </div>
      </section>

      <section id="words" className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-4">
            <div className="word">
              <div className="clipped clipped-crafting"><h2>Crafting.</h2></div>
              <p>We develop digital products carefully, focusing in maximum quality. Our clients are people who believe that their products will make a difference, and so do we. We transform ideas into businesses.</p>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-4">
            <div className="word">
              <div className="clipped clipped-innovating"><h2>Innovating.</h2></div>
              <p>Most products don't succeed. We are here to facilitate your journey to the right direction. Here you can find the innovative environment to transform dreams in products.</p>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-4">
            <div className="word">
              <div className="clipped clipped-unifying"><h2>Unifying.</h2></div>
              <p>Value Proposition Design, Growth Hacking, Lean Analytics, Agile development... Knowing all that (and more) is essencial to create a product. How to connect all those? We developed a method of our own.</p>
            </div>
          </div>
        </div>
      </section>

      <section id="craft">
        <div className="container">
          <h2>How things work here</h2>

          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-4">
              <p>Here at Crafters, we work with clients to define business models and strategic plannings. From that, we craft a list of features and start the construction of a great product.</p>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-4">
              <p>We deliver fast and small releases every week, to gather constant feedback through the whole process. This guarantees improved and better products, reducing risks and increasing the chance of success.</p>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-4">
              <p>We want to work with partners who believe their products can make a difference, who believe that it must be done with collaboration and compromise with success and quality. </p>
            </div>
          </div>
        </div>
      </section>

      <section id="portfolio" className="visible-md-block visible-lg-block">
        <div className="container">
          <h2>Our work</h2>

          <div className="row work text-right">
            <div className="col-xs-12 col-md-4">
              <h3>Conflux</h3>
              <p><a href="http://confluxlab.co">Conflux</a> helps you to manage and create value on your projects, through lean metrics, unified flow and strategic plannig.</p>
            </div>
            <div className="col-xs-12 col-md-8">
              <img alt="" src={confluxImage} />
            </div>
          </div>


          <div className="row work">
            <div className="col-xs-12 col-md-8">
              <img alt="" src={monsterosterImage} />
            </div>
            <div className="col-xs-12 col-md-4">
              <h3>Monster Roster</h3>
              <p>With <a href="http://monsteroster.co">Monster Roster</a> you save time and win money by following our path to a perfect lineup!</p>
            </div>
          </div>

          <div className="row work text-right">
            <div className="col-xs-12 col-md-4">
              <h3>Seu Atleta</h3>
              <p><a href="http://seuatleta.com.br">Seu Atleta</a> helps athletes to bring their dreams to life through crowdfunding.</p>
            </div>
            <div className="col-xs-12 col-md-8">
              <img alt="" src={seuAtletaImage} />
            </div>
          </div>

          <div className="row work">
            <div className="col-xs-12 col-md-8">
              <img alt="" src={learning3Image} />
            </div>
            <div className="col-xs-12 col-md-4">
              <h3>Learning 3.0</h3>
              <p><a href="http://learning30.co">Learning 3.0</a> provides better creative solutions by sharing experiences and benefitting from emergent learning.</p>
            </div>
          </div>

          <div className="row work text-right">
            <div className="col-xs-12 col-md-4">
              <h3>Love Mondays</h3>
              <p><a href="http://lovemondays.com.br">Love Mondays</a> is where you can avaliate companies and search for the perfect place to work. <a href="https://www.glassdoor.com/employers/blog/love-mondays-integrated-glassdoor/" rel="noopener noreferrer" target="__blank">LoveMondays has become glassdoor.</a></p>
            </div>
            <div className="col-xs-12 col-md-8">
              <img alt="" src={loveMondaysImage} />
            </div>
          </div>

          <div className="row work work-4bricks">
            <div className="col-xs-12 col-md-8">
              <img alt="" src={forbricksImage} />
            </div>
            <div className="col-xs-12 col-md-4">
              <h3>4bricks</h3>
              <p>With <a href="http://4bricks.com.br">4bricks</a> you can centralize data and have complete control of all your building projects.</p>
            </div>
          </div>
        </div>
      </section>
    </main>
  </Layout >
)

export default IndexPage
